import { Duration } from 'luxon';

export const formatTimeHHMM = (value?: number) => {
  if (value === undefined) return 'N/A';

  const duration = Duration.fromObject({ minutes: value });

  return duration.toFormat('hh:mm');
}

export const formatTimeHMin = ({
  minutes,
  seconds,
}: {
  minutes?: number;
  seconds?: number;
}) => {
  if (seconds === undefined && minutes === undefined) return 'N/A';

  const totalMinutes = (minutes ?? 0) + Math.floor(((seconds ?? 0) / 60));

  if (totalMinutes < 60) return `${totalMinutes}min`;

  const duration = Duration.fromObject({ minutes: totalMinutes });

  return duration.toFormat(totalMinutes % 60 === 0 ? 'h\'h\'' : 'h\'h\' mm\'min\'');
}

export const formatTimeMin = ({
  minutes,
  seconds,
}: {
  minutes?: number;
  seconds?: number;
}) => {
  if (seconds === undefined && minutes === undefined) return 'N/A';

  const totalMinutes = (minutes ?? 0) + Math.floor(((seconds ?? 0) / 60));

  return `${totalMinutes} min`;
}
