import React from 'react';
import { DateTime } from 'luxon';

import { EmptyState } from '~/components/common/EmptyState';
import { Row, Table } from '~/components/common/Table';
import { Activity as model } from '~/model/Client/Activities';
import { Activity } from './Activity';
import { headerColumns } from './model';
import { Layout } from '../../Layout';

export interface Props {
  activities: model[];
  updatedAt?: string;
}

const rows = (activities): Row[] =>
  activities.map((item: model, index) => ({
    id: item.title,
    content: <Activity activity={item} index={index + 1} />,
  }));

export const Activities = ({ activities, updatedAt }: Props) => {
  const hasActivities = () => activities && activities.length > 0 && updatedAt;

  let layoutProps = {};
  if (hasActivities() && updatedAt) {
    layoutProps = {
      subtitle: `Updated ${DateTime.fromISO(updatedAt).toFormat('dd/MM/yyyy')}`,
    };
  }

  return (
    <Layout title="Activity" {...layoutProps}>
      <>
        {!hasActivities() && (
          <EmptyState
            title="This Client did not log any activities!"
            description="Key tracker and activity log data will be visible here"
          />
        )}
        {hasActivities() && <Table rows={rows(activities)} headers={headerColumns} />}
      </>
    </Layout>
  );
};
