import React from 'react';

import {
  ClipboardCheck,
  ClipboardList,
  KnifeFork,
  CookingBook,
  Bucket,
  FourBlocks,
  Diagnostics,
} from '~/components/common/Icons/dist';

import { Tabs, Tab, TabConfig } from '~/components/common/Tabs';
import { ROUTES } from '~/components/router/Routes';
import { generatePath } from 'react-router-dom';
import { Goals } from './Goals';
import { Questionnaire } from './Questionnaire';
import { Meals } from './Meals';
import { FoodLog } from './FoodLog';
import { Supplements } from './Supplements';
import { Overview } from './Overview';
import { TestResults } from './TestResults';
import { TestKits } from './TestKits';
import { useClient } from '../context';

export type ExistingTab =
  | 'overview'
  | 'goals'
  | 'meals'
  | 'food-log'
  | 'questionnaire'
  | 'test-results'
  | 'test-kits'
  | 'supplements';

export interface Props {
  activeTab?: ExistingTab;
}

export const Sections = ({ activeTab }: Props) => {
  const { clientId } = useClient();

  const TABS: TabConfig[] = [
    {
      id: 'overview',
      title: 'Overview',
      icon: <FourBlocks />,
      content: <Overview />,
      enabled: true,
    },
    {
      id: 'goals',
      title: 'Goals',
      icon: <ClipboardCheck />,
      content: <Goals />,
      enabled: true,
    },
    {
      id: 'meals',
      title: 'Meals',
      icon: <KnifeFork />,
      content: <Meals />,
      enabled: true,
    },
    {
      id: 'food-log',
      title: 'Food log',
      icon: <CookingBook />,
      content: <FoodLog />,
      enabled: true,
    },
    {
      id: 'questionnaire',
      title: 'Questionnaire',
      icon: <ClipboardList />,
      content: <Questionnaire />,
      enabled: true,
    },
    {
      id: 'test-results',
      title: 'Test Results',
      icon: <Diagnostics />,
      content: <TestResults />,
      enabled: true,
    },
    {
      id: 'test-kits',
      title: 'Test Kits',
      icon: <Bucket />,
      content: <TestKits />,
      enabled: true,
    },
    {
      id: 'supplements',
      title: 'Supplements',
      icon: <Bucket />,
      content: <Supplements />,
      enabled: true,
    },
  ];

  return (
    <Tabs variant="floating" activeTab={activeTab}>
      {TABS.filter((t) => t.enabled).map((tab) => (
        <Tab
          key={tab.id}
          id={tab.id}
          testId={`${tab.id}-tab`}
          path={generatePath(ROUTES.CLIENT_TABBED, { id: clientId, tab: tab.id })}
          icon={tab.icon}
          title={tab.title}
        >
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
};
